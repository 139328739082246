import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../components/PageWrapper";
import { Section, Title, Post } from "../components/Core";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Osez tromper votre banquier avec BankBank</title>
                <meta name="description" content="
              La meilleure banque n'est pas la même pour tous ! Changer de banque OK... mais attention à ne pas regretter votre choix de compte." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Osez tromper votre banquier</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Osez tromper votre banquier... 🤫</Title>
			<StaticImage
		  	src='../images/osez-tromper.png'
			width={1200}
			alt='Banque à distance'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
		
			
<h2>Avec la meilleure banque !</h2>
<p>
Depuis quelques années, les banques en ligne ont transformé le marché de la gestion de l’argent des particuliers en le rendant plus transparent, plus accessible, et surtout, beaucoup moins cher. Alors pourquoi écrire un livre sur la banque et les comptes bancaires aujourd’hui ? Le compte bancaire est un des services les plus essentiels pour tous les français aujourd’hui. Qui imaginerait vivre sans une carte bancaire et un compte pour recevoir son argent ? Paradoxalement, au lieu de rendre le choix simple pour tous les utilisateurs, le contexte a amené bien plus de complexité que ce que la révolution des banques en ligne nous a promis. Le manque de transparence est en réalité toujours un souci lorsqu’il s’agit de changer et surtout quant il s’agit de choisir. Choisir la meilleure banque, c’est sélectionner la meilleure solution, adaptée à votre usage en connaissant tout ce qui est proposé sur le marché, sans écran de fumé et sans céder à l’appel d’offres trop alléchantes pour être vraies. Et pour cela, il faut encore savoir décrypter le langage souvent peu clair des banques.
</p><p>
Pour nous inciter à rester dans la même banque, et garder une solution qui n’est pourtant pas la plus économique, on nous explique souvent la même chose : Il est courant d’entendre que le manque à gagner ne vaut pas le temps passé à changer – Que c’est prendre un risque par rapport à notre situation actuelle – Que toutes les banques proposent les mêmes services au final. Nous avons voulu répondre à ces excuses qui, en réalité ne nous paraissent pas vraiment solides puisque tout va dans la direction d’une mobilité plus grande et de plus de choix, comme on l’a vu avec la téléphonie mobile il y’a quelques années. Le pouvoir est entre les mains des consommateurs, et nous voulons affirmer cette idée plus que jamais.
</p><p>
La loi Macron de 2017 est un exemple de la volonté du gouvernement d’en finir avec la complexité bancaire. Aujourd’hui, il est possible d’être dans la banque où l’on doit vraiment être : parce que ça nous coûte moins cher et parce qu’on nous offre des services qui sont vraiment en adéquation avec ce que nous avons réellement besoin au quotidien. Face à cela, les néobanques et les “Fintechs” émergent et gagnent en visibilité. Elles sont ces technologies de la finance qui proposent des alternatives nouvelles dans la manière dont les gens vont dépenser leur argent, économiser, investir… Bref, tout faire. On arrive au un moment où la “multibancarisation”, le fait d’avoir plusieurs comptes et services bancaires avec plusieurs banques, va devenir une norme en France. En rendant populaire de nombreux outils technologiques pour augmenter l’expérience bancaire et surtout la simplifier, les nouveaux acteurs vont faire bouger les lignes et c’est une excellente nouvelle pour tous les clients des banques.
</p>
<h2>
TROUVER LA BANQUE LA MOINS CHERE</h2>
Connaître tous les types de banques pour trouver la banque la moins chère
Pour choisir la banque la moins chère, et surtout celle qui convient exactement à vos besoins, il faut faire la différence entre les trois types de banques qui existent en France. Familière de tous, la banque de réseau a vu son marché secoué par la banque en ligne qui est venue changer les règles il y a quelques années, en offrant un service tout en ligne compétitif à la manière de Free sur le secteur des télécommunications. En plus de ces deux acteurs, des petits nouveaux débarquent depuis quelques mois : les néobanques. Ces dernières ont chacune une spécialité de service qu’elle offrent de manière innovante pour entrer en compétition avec les deux types de banques précédent, ou bien pour les compléter en étant moins chères.

La banque en ligne, 100% digitale
Les banques en ligne ont redessiné le service bancaire depuis quelques années, grâce à des services basés sur une transparence accrue et des tarifs bien plus avantageux. Avec un panel de service aujourd’hui quasiment aussi large que les banques traditionnelles, les banques en ligne sont désormais une référence pour une partie croissante des français (plus de 10% des français est estimé en 2017 par la Banque Française). Depuis 2006 avec comme pionnier Boursorama Banque, elles se livrent une bataille sans merci pour s’approprier le titre de banque la moins chère et pour attirer une part croissante de la population. Les enjeux commerciaux sont considérables : alors que les tarifs des banques traditionnelles de réseau augmentent et généralisent les frais de tenue de compte, les français migrent massivement vers des solutions de banque en ligne.

La banque en ligne, qu’est ce que c’est ?
A partir de 1994, un mouvement d’ouverture commence dans le secteur des comptes bancaires, qui jusque là étaient l’apanage des banques de réseau. Les « bancassureurs » apparaissent ; Axa acquiert « Banque Directe » et, avec AGF (futur Allianz) ; ils proposent une généralisation des services bancaires classiques (carte de paiement et chéquiers). Le vrai pionnier de la banque en ligne comme on la connaît aujourd’hui, c’est Zebank, lancée en 2001, qui vient poser les codes de la banque en ligne avec ses offres spéciales à l’ouverture et son marketing décalé. Son développement, estimé trop novateur a permis de rassembler un peu plus de 100 000 comptes en moins d’un an avant de voir le projet racheté et coulé par son actionnariat. C’est donc à partir du milieu des années 2000 que les services des banques en ligne se structurent vraiment en un marché solide, avec le rachat de Boursorama par Société Générale en 2002, celui de Fortuneo par Crédit Mutuel et le lancement de BforBank par Crédit Agricole.

DES BANQUES COMPLÈTEMENT DÉMATÉRIALISÉES
À ne pas confondre avec la “banque à distance” qui est un service proposé par les banques de réseau qui permet de gérer votre compte client via Internet ou une application mobil. Les banques en ligne sont exclusivement présentes sur internet et proposent leur service sans aucune agence “en dur”, les rendant plus efficaces et plus accessibles à la manière du secteur des voyages : il y a une dizaine d’années à peine, la majorité des consommateurs organisaient leur séjour par l’intermédiaire d’une agence de voyages. Aujourd’hui, c’est moins de 20 % des Français qui s’y rendent encore physiquement. L’émergence de “pure-players” (acteurs 100 % en ligne) tels que Trivago ou Kayak ont facilité ces démarches en les rendant accessibles en quelques clics depuis chez soi 24h/24 et 7j/7. A travers cette évolution du secteur bancaire, chaque client gagne la possibilité de comparer toutes les offres disponibles et de faire le choix qui correspond le mieux à ses besoins et son budget. L’industrie de la banque, du crédit et des assurances suit donc naturellement cette transformation au profit du consommateur. Pour contrer les effets négatifs de cette dématérialisation totale, certaines d’entres elles disposent de quelques agences physiques, notamment Hellobank qui rend accessible les agences de la BNP Paribas (dont HelloBank est issue) pour y déposer des chèques et du liquide.

UNE BANQUE QUI OFFRE DES OPTIONS SIMILAIRES À LA BANQUE EN LIGNE
Les produits et services proposés par les banques digitales sont similaires en tout point aux banques traditionnelles : comptes courants, solutions d’épargne, crédits, contrats d’assurance vie et délivrance de moyens de paiement. Seule la manière dont on communique avec sa banque est modifiée : le service au client est dématérialisé, et se fait donc complètement à distance.

Les avantages et inconvénients de choisir une banque en ligne
LES AVANTAGES :
Moins chères que les banques traditionnelles – voire gratuites
Des livrets d’épargne au taux promotionnels avantageux
La souplesse et la simplicité d’utilisation
L’innovation permanente
La capacité de gérer tout à distance
Des promotions de bienvenue et de parrainage souvent très intéressantes
LES INCONVÉNIENTS :
La perte relative de contact humain
Le nécessité d’utiliser des courriers recommandés pour les dépôts de chèque
Des conditions de salaire ou d’épargne pour les plus avantageuses
Un marketing agressif qui complique la comparaison des banques entre elles
La néobanque, une alternative pour vos besoins précis
Vous êtes peut-être déjà tombé sur quelques articles annonçant la “révolution fintech” et l’apparition des “néobanques”, les challengers des banques traditionnelles et mêmes des plus récentes banques digitales. Il semble que le paysage bancaire ne vas cesser de se transformer dans les années qui viennent : et c’est une très bonne nouvelle, car au coeur de cette révolution, ce sont les nouvelles attentes des clients qui sont posées en priorité.

Les néobanques, qu’est ce que c’est ?
LE TERME DE NÉOBANQUE
La définition de la néobanque est encore bien floue, car ce terme encore très récent englobe tous les nouveaux concurrents des banques qui proposent une carte de paiement et des solutions technologiques pour la gestion de son argent. Les néobanques sont similaires aux banques en ligne sur plusieurs points : ce sont des ”banques” qui sont complètement dématérialisées et peuvent proposer des solutions de compte bancaire ou de transferts, d’épargne, de gestion de compte ou d’autres services financiers. Mais ce qui fait leur vraie différence, c’est de proposer de nouvelles fonctionnalités et de proposer d’enrichir l’expérience client en donnant le contrôle total de vos opérations et options depuis l’application mobile fournie. En cela, elles deviennent de sérieux compétiteurs pour les banques régionales comme les banques en ligne, car elles se rapprochent toujours plus des besoins qui ne sont pas réellement adressés par ces dernières.

L’HISTOIRE DES NÉOBANQUES
Tout d’abord apparues aux Etats-Unis avec une ouverture légale des conditions de création de comptes courants pour les particuliers après la crise de 2009 (Simple lancée en 2012, Moven en 2011, Varo Money et BankMobile en 2015), elles se sont ensuite diffusées au Royaume-Uni (Atom Bank en 2016, Tandem en 2016 , Revolut en 2016, Monzo fin 2015), et en Allemagne (Fidor en 2015 et N26 ancien Number 26 en 2015). Enfin la vague a atteint le reste de l’Europe avec des pionniers novateurs en France : Le Compte Nickel en 2013, Soon d’Axa Banque et Freazy pour les 18-30 ans en 2014 (qui se placent entre la néobanque et la banque en ligne), C’Zam en 2016, Orange Bank qui débarque fin 2017, Morning qui repart aussi fin 2017 après avoir été rachetée par la banque E.Leclerc.

QUEL USAGE POUR LES NÉOBANQUES ?
Chez BankBank, on considère principalement les néobanques comme une option qui s’ajoute à un compte bancaire traditionnel pour des besoins différents. On peut la conseiller principalement pour ceux qui mènent plusieurs vies à la fois et qui désirent une protection particulière dans leurs achats, de l’anonymat ou une séparation des comptes. On pense aussi à une solution de dépannage pour ceux qui ont besoin d’un compte opérationnel en quelques minutes sans besoin de montrer patte blanche; pas de minimum de ressource, pas besoin non plus de justificatifs de domicile ou d’identité contrairement aux banques digitales : certaines néobanques sont aussi simple qu’une carte SIM prépayée. Pour les mineurs, une néobanque peut proposer un compte ajusté à ses besoins et qui permettra aux enfants d’apprendre à contrôler leur argent, à l’heure de la disparition progressive de l’utilisation de liquide pour les paiements.

Enfin, pour ceux qui ont un pied dans plusieurs pays à la fois ou qui voyagent beaucoup : les néobanques présentent de toutes nouvelles solutions sur les transferts internationaux, les commissions de change, la détention de plusieurs comptes dans plusieurs pays à la fois.

Les néobanques, ça sert à quoi?
LES NOMBREUSES OPTIONS QU’OFFRENT LES NÉOBANQUES
Chaque néobanque répond à un besoin différent et permet aux clients des banques de profiter de services additionnels à leur compte bancaire de base, pour des tarifs bien moins élevés.
Voici une cartographie actuelle des néobanques en fonction de leurs orientations :

Toutes les néobanques offrent un carte de paiement international à débit différé. Pour le reste, chacune proposera des solutions financières adaptées à des profils différents.

N26 :

La néobanque la plus complète à ce jour, elle est capable de remplacer un compte courant et plus :

Transferts en devise étrangère avec Transferwise (taux de change réel avec commission bancaire réduite)
5 retraits gratuits par mois en Europe, puis 2€ par retrait
Retrait hors zone Euro : 1,7% et gratuit avec carte Black (à 5,9€/mois)
Compte courant avec possibilité de recevoir son salaire dessus
Compte gratuit si 9 achats par trimestre. Sinon 2,70€ par mois.
Possibilité de bloquer la carte temporairement
Inscription en moins de 10 minutes avec un passeport ou une carte d’identité uniquement
Pas de condition de salaire minimum
Paiement sans contact
IBAN avec possibilité de recevoir un salaire et faire des prélèvement et virements
Revolut :

Pour ceux qui jonglent entre plusieurs monnaies :

Possibilité de bloquer la carte temporairement
Inscription en moins de 8 minutes avec un passeport ou une carte d’identité uniquement
Un compte courant Euro ou livre Sterling interchangeables
La possibilité de changer ses devises aux taux de change réel sans commission bancaire
La possibilité de payer avec plusieurs monnaies pour le même compte
Pas de condition de salaire minimum
Prêts à la conso sur-mesure de 500 à 5000€, remboursements de 12 à 60 mois
Retraits gratuits en Europe jusqu’à 200€ par mois (au delà, 2% majoré)
Paiement sans contact
Une application qui classe vos dépenses pour vous faire un bilan mensuel super précis de votre budget
Pas d’IBAN pour domicilier son salaire ou recevoir des virements
Carte pas prise en charge par les distributeurs automatique d’essence
SOON d’Axa banque :

Gratuit avec minimum d’achat (900€ par trimestre, 450€ pour les moins de 26 ans)
Retraits gratuits et illimités en Europe
Un compte de dépôt individuel adossé à un livret d’épargne
Le stockage de documents (photos, garanties, factures, etc.) gratuit
L’accès aux conseillers Soon par chat via l’appli 24h/24, 6 jours sur 7
Alertes Sms gratuites (6 maximum par mois)
Inscription avec : Carte d’identité, RIB d’une autre banque, Justificatifs de revenus, Justificatif de domicile
Gestion des dépenses et des plafonds sur l’application
Gestion de la carte (blocage, déblocage) depuis l’application
Le Compte Nickel :

Sans condition de revenus ou de dépôts;
L’ouverture du compte s’effectue en 5 minutes chez les buralistes agréés
Transferts et prélèvements sur IBAN non personnel (mais redistribué par C’Zam vers votre compte avec votre nom).
Un compte 12-18 ans proposé
Le compte C’Zam :

Avec un Relevé d’Identité Bancaire à votre nom (RIB / IBAN)
Accessible à tous sans conditions de revenus
Domiciliation de prélèvements
Réception de transferts (comme salaires)
Achat du coffret depuis 2000 points de ventes carrefour en France pour 5€
Compte à 1€ par mois
1€ par retrait dans un distributeur zone euro autre que Carrefour Banque et BNP Paribas et 1€ par retrait hors zone euro
Ouverture du compte en quelques minutes avec 2 justificatifs d’identité
Le compte Ipagoo (qui arrivera à maturité en 2018) :

Capacité de détenir des comptes en plusieurs devises (EUR, GBP et USD)
Choix du type d’option pour les transferts (urgents/normaux)
Le compte Orange Bank :

Un découvert autorisé
Un livret d’épargne
Gratuit, sous condition d’effectuer au moins 3 paiements ou retraits par mois, sinon 5€ par mois)
LES AVANTAGES ET INCONVÉNIENTS DES NÉOBANQUES
Les avantages :

Une simplicité d’utilisation et d’inscription encore jamais vue
Le prix réduit
Pas d’engagement
Les toutes nouvelles technologies à portée de main
Une option d’utilisation en combo avec un compte classique pour vos des besoins spécifiques
Les inconvénients :

Il existe beaucoup d’offres différentes, et il est donc difficile de faire son choix
A quelques exception près, elles ne sont pas des offres qui se suffisent à elles même pour la gestion de votre argent
La banque de réseau
HSBC, Crédit Agricole, Caisse d’épargne, Crédit Mutuel, Société Générale, BNP Paribas, sont les grands noms des banques traditionnelles que chaque français connaît bien. Les banques dites “traditionnelles” ou “de réseau” disposent d’un réseau local d’agences de proximité. Elles se chargent de collecter et de gérer les fonds de leurs clients en proposant la plupart des services bancaires, du compte courant au produit d’épargne, en passant par les solutions de crédit ou même les contrats d’assurance-vie. La plupart d’entre elles proposent des services de banques à distance permettant de consulter et gérer des opérations bancaire en ligne.

La banque traditionnelle, banque de réseau
Des siècles d’évolution de la notion de banque comme service aux particuliers ont vu se dégager plusieurs types d’organisation des banques qui peuvent être aujourd’hui soit commerciales, coopératives ou publiques.

LA BANQUE COMMERCIALE : LE PRIVÉ QUI DIRIGE
La banque commerciale est une entreprise privée destinée à obtenir des bénéfices commerciaux sur toutes les opérations qu’elle réalise. Elle est constituée par un capital détenu par des actionnaires extérieurs et privés qui ne font pas partie de ses clients. La Société Générale, BNP Paribas, HSBC ou le CIC sont des banques commerciales.

LA BANQUE COOPÉRATIVE : LA PLACE AUX CLIENTS SOCIÉTAIRES
Une banque coopérative (ou mutualiste) se caractérise par l’absence d’actionnaires privés. Les propriétaires sont les clients et usagers de la banque. Propriété de chacun des clients collectivement, la banque coopérative fonctionne sur un principe démocratique : les clients sociétaires participent à l’élection des dirigeants, qui fondent la ligne directrice de la politique à mettre en place, mais ils peuvent aussi participer aux prises de décisions lors de réunions plus ou moins fréquentes. Parmi les banques coopératives, on peut citer la Caisse d’Épargne, le Crédit Agricole ou la Banque Populaire.

LA BANQUE PUBLIQUE : UNE BANQUE PROPRIÉTÉ DE L’ÉTAT
La banque publique est un établissement bancaire public dont l’État ou des acteurs publics sont propriétaires. Autrefois publique, la Banque Postale est devenue récemment une société anonyme et donc se rapproche d’une banque commerciale à la différence que ses capitaux sont encore publics.

Les avantages et inconvénients des banques de réseau traditionnelles :
Les avantages :

La proximité et le contact personnel avec un conseiller
Des solutions sur-mesure pour vos problèmes
La notoriété
La confiance dans leur solidité financière
En général la plus large gamme de produits et de services
Un service de banque à distance proposé par chacune
Les inconvénients :

Plus chères que les banques en ligne ou néobanques
L’opacité des tarifs, des formules et des options
La file d’attente, parfois longue, pour effectuer certains changements
Une perte de vitesse de la proximité à cause de la centralisation des agences
Combien vous coûte votre carte bancaire?
Choisir une banque moins chère, c’est choisir les services dont on a besoin quotidiennement, et c’est comprendre ce pour quoi l’on paye, en détail et en transparence. On vous propose donc d’explorer le panel des frais bancaires existants, en commençant par le plus évident : votre carte bancaire. Dans un second temps, on fournira une explication pour chaque frais que l’on retrouve chez toutes les banques. Grâce à ces informations, il sera plus simple de vous situer et de faire le meilleur choix parmi les nombreuses formules bancaires.

Les notions de base sur les cartes bancaires
La carte bancaire, c’est l’élément central du compte bancaire. C’est le moyen de paiement le plus simple, le plus sécurisé et le plus pratique de l’industrie bancaire française. Selon une étude de la Banque de France sur les moyens de paiements scripturaux, l’utilisation de la carte bancaire ne cesse de progresser depuis 2000 : + 8 % en moyenne par an en France, elle représentait déjà plus de 50 % des paiements en 2014. 71 % des Français ont déclaré que la carte bancaire est leur moyen de paiement préféré (étude CSA – mai 2015) et 92% des français utilisent en priorité la carte bancaire comme moyen de paiement. (sondage OpinionWay, mai 2016).

On comprend alors pourquoi la cotisation pour bénéficier d’une carte bancaire représente un des coûts les plus importants dans les frais annuels des banques traditionnelles. C’est aussi sur ce point que les banques en ligne ont décidé de réduire les frais en mettant généralement à disposition du client une carte de paiement gratuite.

Les différents types de cartes
La carte de retrait : pour une gestion du budget facilitée
Une carte de retrait, comme son nom l’indique, permet uniquement d’effectuer des opérations de retrait dans les distributeurs automatiques de billets (DAB) ou les guichets automatiques des banques (GAB) et est toujours limitée au montant qui est placé dessus (ce type de carte est à débit immédiat). Ces retraits peuvent être limités au réseau de la banque, ou bien réalisables dans tous les distributeurs nationaux (voir internationaux). Les retraits sont généralement plafonnés par les banques par opération et sur une période de 7 jours.

Parmi les cartes de retrait, on peut citer les cartes Cirrus Mastercard ou Visa Plus.
Cette carte est destinée à ceux qui souhaitent apprendre à gérer leur argent ou surveiller de près leur dépenses, les enfants et les jeunes, les personnes en difficultés financières, les situations particulières et temporaires. Chez les banques de réseau, elle sera en général proposée entre 15€ et 30€ par an. Ce type de carte est progressivement délaissé à la fois par les banques et leurs clients du fait qu’elles sont limitées aux retraits et que les cartes à débit immédiat ou à contrôle de solde permettent bien plus d’actions tout en étant proposées au même tarif, voire parfois moins chères.

Les avantages :

Apprentissage facilité de la gestion de budget
Maîtrise des dépenses
Peu coûteuse
Les inconvénients :

Opérations limitées
Pas d’intérêt par rapport à des types de cartes concurrentes qui sont au même tarif voire moins chères.
La carte de paiement : la plus répandue
La carte de paiement est le type de carte le plus répandu. Elle peut-être à débit immédiat ou différé et permet d’effectuer une diversité d’opérations :

Retraits aux distributeurs automatiques de billets
Paiement sur des terminaux destinés à ce type d’opération
Paiement sur internet
Ces opérations peuvent être limitées au territoire national mais aussi s’étendre à l’étranger. Différentes gammes sont proposées, de la carte très haut de gamme à la carte à autorisation systématique.

Pour qui ? L’étendue des produits proposés permet à chacun de trouver la carte correspondante à son profil et ses besoins.

À quel prix ? Environs 30€ à 330€ euros selon les gammes

La carte de crédit pour les dépenses exceptionnelles
La carte de crédit est également une carte de paiement à la différence que celle-ci ne puise pas l’argent sur un compte bancaire mais dans une réserve de crédit. Elle est donc associée à un crédit renouvelable ou dit “revolving”. Ce crédit consiste à de l’argent prêté temporairement par la banque qui vous accorde le découvert, et qui charge cet emprunt à un taux fixé par elle, ainsi que des commissions d’intervention si ce découvert n’était pas autorisé.

La carte de crédit permet d’effectuer toutes les opérations courantes telles que des retraits à des distributeurs automatiques, paiements chez les commerçants équipés de terminaux de paiement ou bien achats sur internet.

Le propriétaire reçoit mensuellement un récapitulatif des dépenses effectuées avec la carte et rembourse les mensualités adossées à ces dépenses avec des intérêts.

Pour qui ? Ceux qui disposent des fonds suffisants pour solder le crédit et qui souhaitent réaliser des dépenses imprévues ou onéreuses.

À quel prix ? De 0€ à plus d’une centaine d’euros par an, selon le type de carte et la banque qui l’offre.

La carte prépayée rechargeable : le porte-monnaie électronique
Arrivée en 2011 sur le marché français, la carte prépayée est une carte de crédit rechargeable au gré de ses besoins et qui ne nécessite pas de disposer d’un compte bancaire pour être utilisée. Le particulier peut créditer la somme qu’il désire sur sa carte sans pouvoir la dépasser une fois épuisée : un atout considérable pour bien gérer son argent. Son utilisation s’étend à l’international et certaines d’entres elles disposent de fonctionnalités plus élaborées permettant de recevoir ou d’envoyer de l’argent. La carte prépayée se rapproche de la carte de paiement international de débit offerte par les toutes nouvelles néobanques qui se gèrent depuis une application mobile. Vous rechargez votre compte bancaire et l’argent est directement disponible sur la carte, pas plus pas moins.

La carte Veritas Mastercard ou la carte Jeunes Visa sont des exemples des cartes prépayées existantes actuellement.

Pour qui ? Ceux qui souhaitent gérer leur budget, les jeunes.

À quel prix ? De 10€ à 180€ par an.

La Banque Postale
Carte prépayée seule : 19,00€/an
Frais de gestion après échéance des cartes bancaires prépayées (7 mois après réception et usage): 1,00€/mois
Frais de tenue de compte 1,00€/mois
Soit après 7 mois : 33€/an pour la carte seule

Une carte à débit immédiat ou différé ?
La carte à débit immédiat
Une carte à débit immédiat se caractérise par un décompte instantané (dans les 24 à 48 heures) des opérations effectuées avec cette même carte. Elle est généralement moins coûteuse qu’une carte à débit différé.

Les avantages :

Suivi en temps réel du solde du compte
Contrôle des dépenses
Simplicité
Les inconvénients :

Impossibilité de dépasser les limites du solde de compte (et du découvert autorisé)
Frais engagés en cas de rejet de prélèvement
La carte à débit différé
Une carte à débit différé dispose d’un regroupement des paiements effectués avec la carte en un seul prélèvement mensuel. Les retraits sont toutefois débités directement. La date de prélèvement peut être convenu entre le client et la banque ou bien imposée par celle-ci en début ou fin de mois. Le particulier dispose ainsi d’un mois pour effectuer des dépenses librement sans être bloqué par le solde disponible sur son compte.

Les avantages :

Souplesse des dépenses
Règlements de sommes onéreuses ou imprévues
Règlements de sommes dont le client ne dispose pas encore
Les inconvénients :

Gestion rigoureuse des dépenses nécessaire
Frais engagés en cas de règlement insuffisant au moment de l’échéance
Les cartes de paiement sans contact
Le paiement sans contact progresse chaque année en France. Il représente plus de 40,7 millions de cartes bancaires, soit plus de 61% de la totalité des cartes en France (Observatoire du NFC & du sans contact, analyse réalisée en mai 2016) et on compte aujourd’hui plus de 408 700 commerçants en France qui la proposent. Son aspect très pratique séduit de plus en plus, que ce soit pour payer un verre dans un bar bondé ou pour réduire la durée d’attente des files dans les supermarchés, c’est la solution rapidité la plus efficace. En plus, elle sont limitées à un plafond de 20€ à 30€ par transaction pour vous éviter de risquer trop gros en cas de tentative de piratage de votre carte à distance. La plupart des offres des banques digitales les proposent en premier choix, tandis que les banques de réseaux s’en équipent de plus en plus majoritairement.

Le système de cashback
Mis en place par quelques banques, généralement pour récompenser le passage d’une carte bancaire classique vers du “haut de gamme”, le système de cashback consiste à reverser un pourcentage minime de la somme investie dans un achat sur le compte du détenteur de la carte. Ce pourcentage variera en fonction des partenariats ou bien s’appliquera uniformément sur tous les achats à partir d’un montant minimum, et peut représenter une offre très attractive pour ceux qui dépensent et veulent faire des bonnes affaires. Une autre alternative du cashback, c’est de constituer une cagnotte qui sera disponible pour un achat parmi des options proposées par la banque ou bien dans les catalogues de partenaires.

Le système de paiement sans carte
En concurrence avec les cartes qui se multiplient, des applications dédiées ont été créées en partenariat (ou non) avec les banques françaises pour simplifier les formes de paiement en un seul geste : toucher avec son portable un terminal de paiement pour réaliser tous ses achats (avec toutes ses cartes, qui sont intégrées dans l’application).

Apple Pay pour les détenteurs des derniers mobiles IPhone
Apple Pay est une application de paiement par mobile lancée récemment par Apple et qui s’installe doucement en France après un franc succès aux Etats-Unis. A travers l’application téléchargeable sur votre iPhone 6 ou un modèle plus récent, vous pouvez régler vos achats sans carte bleue chez les commerçants partenaires. Approchez votre portable ou votre montre Apple vers le terminal de paiement proposé par le vendeur, posez votre doigt sur l’écran pour vous identifier grâce à votre empreinte digitale, et le paiement s’effectue. Ce moyen hyper-sécurisé et très pratique s’installe progressivement à travers des partenariats avec quelques banques et quelques Fintechs.

Pour l’instant, seuls les clients des banques partenaires pourront en profiter :

La Banque populaire
La Caisse d’Epargne
Carrefour Banque
Les portefeuilles Boon, Orange Cash et Ticket restaurant
Le Crédit Mutuel de Bretagne
Le Crédit Mutuel du Sud-Ouest
Le Crédit Mutuel Massif Central
Fortuneo
Le Crédit Mutuel Arkéa
La BCP
Orange Bank
N26
La solution mobile Lydia (qui gère les échanges de petites sommes entre particuliers)
Ce moyen est une alternative sûre au paiement par carte sans contact puisque le mobile que vous utilisez pour le paiement vous demandera votre identification pour chaque paiement. Cela évite toute tentative de piratage de votre carte à distance, qui arrive rarement mais qui n’est pas impossible.

Paylib pour les détenteurs d’un mobile qui fonctionne sous Android
Il y a donc du mieux, car les efforts fournis par Apple Pay pour convertir les français au paiement sans carte sont rejoints par Paylib, dont l’avantage est l’interbancarité (elle est née du partenariat entre les banques majeures françaises). Depuis son lancement en septembre 2013, d’abord comme alternative à la carte bancaire pour le paiement en ligne, Paylib a rallié la majorité des grandes banques de détail françaises :

La Banque Postale
La Société Générale
BNP Paribas
Le Crédit Mutuel Arkéa
Le Crédit Agricole
Le système est le même qu’Apple Pay, mais cette fois la limite du système repose dans son application au seuls mobiles sous Android (et donc non acceptés par les iphone et les portables tournant sous système Windows). Pour que votre portable fonctionne avec cette option, il faut aussi qu’il soit équipé de la technologie NFC et qu’il fonctionne sous opérateur Android.

Orange Cash, l’option pour les clients d’Orange
Orange Cash fonctionne comme ses pairs, mais a l’avantage d’être compatible aussi bien sur iOS que sur Android, et même Windows Phone. Ce service a été lancé par l’opérateur en partenariat avec Visa et Wirecard. Il nécessite un code ou bien son empreinte digitale pour renseigner son identité et confirmer le paiement. Le montant maximum pour ce système est de 250€.

Mais Orange Cash est réservé aux abonnés d’Orange, de Sosh ou de M6 Mobile, y compris avec un forfait bloqué. La mobicarte est exclue du programme. En plus d’un Smartphone qui est équipé NFC, une carte SIM qui est elle même NFC est indispensable. Celle-ci est fournie gratuitement par Orange. Seuls les terminaux iOS peuvent s’en passer.

Contrairement aux autres systèmes de paiement par mobile, il faut créditer son compte par carte bancaire directement dans l’application, par virement bancaire ou par coupon recharge. Sauf sur un iPhone, avec lequel il suffit d’associer son compte au Wallet Apple Pay.

Le dernier arrivé : Samsung Pay
Samsung Pay, qui consiste exactement à la copie d’Apple Pay, est disponible depuis septembre 2017. Le nombre de banques concernés devrait être encore limité pour le lancement. Bien sûr, seulement les derniers modèle Samsung équipés de la puce NFC pourront activer cette option.

Les conseils BankBank :
Chez toutes les banques partenaires, l’inscription au service de paiement en ligne Paylib est gratuite. Si vous êtes inquiet du manque de protection qui touche les paiements avec carte sans contact, ces méthodes présentent une alternative intéressante et bien plus sécurisée.

Les différentes gammes de carte bancaire
En fonction des besoins et des capacités, il existe des solutions et des gammes de cartes pour chacun.

L’entrée de gamme : la carte à contrôle systématique du solde
La carte à contrôle systématique du solde permet d’effectuer des paiements et retraits et met à disposition uniquement l’argent qui est placé dessus (c’est une carte à débit immédiat). Avec cette carte, la banque vérifie à chaque fois que la provision du compte est suffisante pour régler l’opération effectuée. Dans le cas où la provision est insuffisante, l’opération sera refusée. Il faut donc faire attention en cas de demande de caution élevée, par exemple si vous louez une voiture ou bien sur des automates sans demande d’autorisation qui conservent un droit de retrait sur votre carte.

Certaines cartes sont destinées aux opérations en zone euro comme la Visa V Pay ou la Maestro l’Autre Carte tandis que la Visa Electron ou la Maestro offre la possibilités d’effectuer des opérations à l’international.

Conditions de revenus : Aucune
Plafonds de retrait : De 300€ à 450€ sur 7 jours dans les distributeurs automatiques des autres banques (jusqu’à 800€ dans les distributeurs de la banque)
Plafonds de paiement : De 750€ à 2 400€ par mois

Pour qui ? Ceux qui souhaitent maîtriser leur budget et de la simplicité

À quel prix ? De 0€ à 50€ par an.

Les avantages :

Maîtrise du budget
Simplicité
Peu coûteuse
Les inconvénients :

Incompatibilité avec certains terminaux de paiement ou automates
Limitation en cas de besoin exceptionnel
Plafonds relativement limités par défaut
Cette carte est comprise dans la Formule de Compte Simplicité par la Banque postale, qui offre pour 27,60€ par an; les relevés de compte mensuel en ligne ou par courrier, l’accès à la gestion de vos comptes sur Internet, le service d’Alertes Multimédia (3 alertes incluses par mois), 2 chèques de banque par mois, 4 virements SEPA (dont 1 virement permanent) par mois.

Le milieu de gamme : la carte classique internationale
Les cartes classiques internationales Visa Classic et Mastercard sont les plus emblématiques et les plus répandues. C’est la carte bleue telle qu’on l’appelle familièrement. Elle permet de faire des retraits et paiements nationaux et internationaux, des achats sur internet et des autorisations de prélèvement. Pour cette carte, vous avez la possibilité de choisir entre le débit immédiat ou différé, à noter que la cotisation pour la seconde option est généralement plus élevée. Les cartes Visa Classic et Mastercard bénéficient de la garantie de base d’assurance et d’assistance à l’étranger.


Conditions de revenus : Aucune (mais il vaut mieux disposer de revenus réguliers) sauf pour les banques en ligne fixant des revenus minimums allant de 1 000€ à 1200€ par mois.
Plafonds de retrait : De 300€ à 1 500€ sur 7 jours
Plafonds de paiement : De 1 500€ à 3 050€ par mois

Pour qui ? Ceux qui souhaitent s’adapter à toutes les situations

À quel prix ? De 0€ à 90€ par an à débit immédiat (un peu plus pour un débit différé).

Les avantages :
Elle propose tous les services d’une carte bancaire internationale
Elle vous protège avec une assurance et une assistance internationales
Elle est accessibles pour la majorité des profils

Chez la Banque Postale :
Sans formule, la carte Visa Classic seule coûte 39€/an en débit immédiat et 44,50€/an en débit différé (auxquels il faut ajouter 12€ de frais de compte par an).
La formule de Compte équipée d’une Visa Classic en débit immédiat est facturée 83,04€/an (avec assurance Réaltys Plus) et 70,56€/an (avec assurance Réaltys classique et les autres services inclus dans la formule complète).
En débit différé, la formule de compte est facturée à 75,6€/an (avec assurance Réaltys classique et les autres services inclus dans la formule complète) et 88,8€/an (avec assurance Réaltys Plus).

Les cartes haut de gamme Gold et Premier
Les cartes Gold Mastercard, Visa Premier ou encore les American Express Gold constituent le niveau de base des cartes en haut de gamme. Elles présentent les mêmes caractéristiques que les cartes classiques Visa ou Mastercard mais elles offrent une couverture d’assurance et une assistance à l’étranger plus complète. Elles permettent aussi de profiter des avantages des partenaires en hôtellerie, voyage ou parfois de cashback.
Bien sûr, elles coûtent généralement plus cher et les banques exigeront généralement un salaire minimum assez élevé pour la proposer à leurs clients.

Conditions de revenus : De 1 200€ / mois à 40 000€ / an
Plafonds de retrait : De 500 à 1 500€ sur 7 jours ( jusqu’à 2 000€ dans les distributeurs de la banque)
Plafonds de paiement : De 1 200€ (à l’international) à 8 000€ par mois

Pour qui ? Pour ceux qui voyagent régulièrement et qui veulent une couverture plus grande

À quel prix ? De 0€ à 149€ par an en débit immédiat (135€ en débit différé)

Les avantages :

Assurance et assistance à l’étranger plus étendues
Avantages des partenaires
Les frais de retraits aux distributeurs automatiques des autres banques gratuits et illimités en zone euro
La Banque Postale :
Sans formule, la carte Visa Premier en débit immédiat ou différé seule coûte 75€/an la première année et 127€/an ensuite (auxquels il faut ajouter 12€ de frais de compte par an).
La formule de Compte équipée d’une Visa Premier en débit immédiat ou différé est facturée 75€/an (avec assurance Réaltys Plus) puis 156€/an (avec assurance Réaltys classique et les autres services inclus dans la formule complète).

Les cartes Prestige, Platinum, Infinite et World Elite
Une montée de gamme plus haut : les cartes prestiges dont la Visa Infinite, World Elite Mastercard ainsi que la Visa Platinum et l’American Express Platinum. Le top du top, seulement pour les gros salaires.

Les avantages supplémentaires ? Une assurance et assistance encore plus étendues avec des plafonds de prise en charge plus élevés, un service de conciergerie et même des invitations à des soirées prestigieuses. A noter toutefois que les services des Platinum sont plus limités que ceux de l’Infinite et la World Elite, qui sont aussi plus chères.
Conditions de revenus : De 4 000€/ mois à 80 000€ / an
Plafonds de retraits : De 3 000€ à 5 000€ sur 7 jours
Plafonds de paiement : De 5 000€ (à l’international) à 150 000€ par mois.

Pour qui ? Ceux qui voyagent souvent, qui souhaitent bénéficier d’avantages plus importants et d’un service de conciergerie.

À quel prix ? De 195€ à 360€ par an. Pour les Platinum, de 170€ à 270€ par an à débit immédiat (un peu plus pour un débit différé).

Les avantages :

Des garanties d’assurance et d’assistance supérieures
Un service de conciergerie souvent offert en parallèle
Les frais de retraits aux distributeurs automatiques des autres banques gratuits et illimités en zone euro et même souvent hors zone euro
Les conseils BankBank :
Les conditions de revenus, les plafonds de retrait et de paiement diffèrent selon les banques et sont souvent personnalisables si vous contactez votre banque pour les changer. Attention, cette option peut être payante, renseignez vous avant de l’activer.

Les Assurances liées aux cartes bancaires
Choisir sa carte c’est aussi et surtout choisir une assurance et des services qui vont avec. Comprise dans la cotisation de la carte, l’assurance et l’assistance à l’étranger varient en fonction de la gamme de carte choisie, ce qui signifie que plus on monte en gamme plus les garanties et les plafonds seront étendus.

Les garanties d’assurance offertes par les banques sont en général alignées sur les informations qu’on trouve sur les sites des cartes bancaires Mastercard et Visa. Mais des différences peuvent exister. Elles sont décrites sur un contrat que vous aurez signé à l’ouverture de votre compte bancaire, qui définit précisément quelles sont les obligations de la banque envers vous et vos proches, mais aussi leurs limitations. Il est très important d’y jeter un coup d’œil avant tout voyage pour savoir comment vous êtes couvert et éventuellement vous poser la question du besoin d’un complément d’assurance.

Les garanties d’assurance de votre carte bancaire
Les garanties d’assurance telles que décès et invalidité, responsabilité civile, perte, vol ou détérioration des bagages, location de véhicule, annulation ou interruption de voyage, retard d’avion ou de train sont liées à l’usage de la carte. Pour bénéficier de l’assurance, il faut que le paiement ait été effectué avec la carte (titre de transport, location…).

La garantie décès et invalidité
Elle vous couvre sur les risques de décès et d’invalidité permanente suite à un accident lors d’un voyage privé ou professionnel à l’étranger, dans un transport public ou une voiture de location. C’est une somme versée sur votre compte celui de vos proches pour “compenser” les conséquences de l’incident. Pour vous donner un ordre d’idée, le capital peut être de 620 000€ sur une World Elite Mastercard et une Visa Infinite, 465 000€ sur Visa Platinum, 310 000€ pour une Visa Premier, jusqu’à 98 000€ pour une Mastercard et 46 000€ pour une Visa Classic.

La garantie responsabilité civile
Elle garantit les dommages corporels et matériels provoqués à un tiers : accident de voiture, responsabilité dans un incident qui crée des dommages matériels… Cette garantie vous couvre dans la plupart des cas si vous n’avez pas volontairement provoqué ces dégâts. Seules les cartes haut de gamme Visa Premier, Gold Mastercard et prestige World Elite Mastercard et Visa Infinite prévoient cette couverture. A savoir que cette garantie est fortement conseillée car les histoires d’accident involontaires malheureux qui amènent à être responsable de soins lourds (et très chers) pour autre personne ne sont pas rares. Si vous n’êtes pas couvert par cette assurance, cela peut être un bonne idée de vous faire assurer par un assureur international, qui vous coûtera autour des 120€ par mois à l’étranger, en fonction de votre destination évidemment.

Quant aux plafonds des cartes qui proposent cette assurance, ils s’élèvent à 5 millions d’euros pour la World Elite, 4,6 millions d’euros pour les dommages corporels et 1, 525 millions d’euros pour les dégâts matériels avec une carte Infinite, 2 millions d’euros avec une Gold Mastercard et 1,525 millions d’euros avec une Visa Premier.

La garantie perte, vol ou détérioration des bagages
Cette garantie couvre la perte, le vol et détérioration, totale ou partielle des bagages et n’est proposée que par les cartes haut de gamme et prestige. Les dédommagements sont de 1 900€ par bagage (dont 600€ par objet de valeur) avec une World Elite Mastercard, 1 800€ par bagage avec une Visa Infinite, 850€ par bagage (dont 300€ par objet de valeur) et jusqu’à 1 600€ par an avec une Gold Mastercard et 800€ par bagage (dont 250€ par objet de valeur) avec une Visa Premier.

La garantie location de véhicule
Elle couvre le vol ou les dégâts matériels du véhicule que vous avez loué. L’assurance prend en charge les frais de réparation ou la valeur totale du véhicule à la charge du loueur. Cette assurance ne concerne que les cartes haut de gamme, à partir de la Visa Premiere et la Mastercard Gold. La prise en charge est plafonnée à 100 000€ avec un carte World Elite Mastercard et 50 000€ avec une Gold Mastercard. Quant aux Visa Infinite et Premier, le plafond n’est pas informé.

La garantie annulation ou interruption de voyage
Bon nombre de raisons peuvent vous conduire à annuler ou interrompre votre voyage, que ce soit à cause d’un problème de santé, la perte d’un emploi ou le décès d’un proche. Ces situations sont couvertes par les cartes haut de gamme et prestige à hauteur de 10 000€ avec une World Elite Mastercard, Visa Infinite ou Platinum American Express, 7 500€ avec la Visa Platinum et 5 000€ avec une Gold Mastercard ou une Visa Premier. Evidemment, il faudra justifier l’annulation ou l’interruption de voyage et la charge de la preuve vous incombera. Un conseil : conservez toutes les traces de vos communications à ce sujet et des causes qui vous poussent à annuler.

La garantie retard d’avion ou de train
Posséder une carte haut de gamme ou prestige peut également vous couvrir en cas de retard de votre avion ou de votre train. Un retard de plus de 4 heures pour un trajet normal (6 heures pour un charter) et si aucun autre moyen de transport n’est mis à disposition alors les frais de première nécessité (hébergement, repas, modification de billet…) seront prises en charge jusqu’à votre arrivée à destination finale.

La prise en charge de ces frais de première nécessité s’élève à 450€ maximum avec une Gold Mastercard et 400€ avec une Visa Premier. Les cartes prestige quant à elles, indemnisent le titulaire jusqu’à 900€ pour une World Elite Mastercard et 840€ avec une Visa Infinite.

Les garanties d’assistance de la carte bancaire
La carte MasterCard et Visa assistent le titulaire et sa famille dans les situations d’urgence, en France et à l’étranger. Les garanties d’assistance médicale, juridique ou d’urgence sont activées par la seule détention de la carte, et non son utilisation, contrairement aux garanties d’assurance. Il vous suffira d’avoir bien réglé vos abonnements à la banque et de vérifier que votre carte n’est pas expirée pour être couvert.

Lorsque votre voyage dépasse une durée prévue de 90 jours, vous devrez dans la plupart des cas souscrire à une assurance internationale qui prend le relais pour votre sécurité. En effet, les garanties d’assistances sont généralement limitées aux voyages de moins de 90 jours.

Les conseils BankBank :
Informez vous sur les conditions de l’assurance liée à votre carte bancaire. En effet, c’est votre banque qui vous assure et non pas la marque Visa ou Mastercard ; les conditions de l’assistance sont donc décrites par votre banque. Et celle-ci est susceptible d’appliquer en totalité ou seulement en partie les garanties associées en principe à votre carte Visa ou Mastercard.

L’assistance rapatriement
En cas d’accident, le rapatriement médical urgent est couvert pour vous et votre famille vers votre lieu de résidence ou vers un hôpital en ambulance, train ou avion. Toutes les cartes de paiement contiennent cette assistance. En cas de décès, le transfert du corps du défunt se fera vers le lieu des obsèques et sera couvert par l’assistance rapatriement.

L’assistance médicale
Si vous ou vos proches êtes amené à recevoir des frais médicaux ou à être hospitalisé lors d’un voyage à l’étranger, la part des frais non remboursés par la sécurité sociale peuvent être pris en charge par l’assistance médicale.
Avec une carte Infinite, le remboursement est plafonné à 156 000€ par assuré et par année après déduction d’une franchise de 50€. Avec une World Elite Mastercard, une Gold Mastercard ou une Visa Premier ce plafond est de 155 000€ après déduction d’une franchise de 75€ pour les Mastercard et 50€ pour Visa. Enfin, les autres cartes bénéficient d’un remboursement à hauteur de 11 000€. Certains frais dentaires d’urgence peuvent aussi être pris en charge par cette assistance.

Assistance retour anticipé
Cette assistance est mise en place dans le cas d’une hospitalisation ou du décès d’un proche. Deux options s’offre au titulaire : obtenir un billet d’avion aller-retour ou 2 billets aller simples. La classe de votre billet sera déterminée par la gamme de votre carte : les Visa Classic et en dessous, les billets seront en seconde classe, tandis que pour les gammes au dessus, ils seront en première classe.

L’assistance juridique
Si vous faites l’objet de poursuites judiciaires à l’étranger suite à une infraction non intentionnelle, l’assistance juridique peut vous faire une avance sur les frais engagés et vous remboursera dans certaines conditions une partie des frais d’avocats. Vous devrez signer une reconnaissance de dette de la caution pénale ainsi que des honoraires de l’avocat auxquels elle contribue.

Ces avances et cette participation avec une Visa Infinite ou une Visa Premier sont fixées à 16 000€ pour la caution et 16 000€ pour les frais d’avocat (avec une participation maximale de 3 100€), pour une World Elite Mastercard elles sont de 15 500€ pour la caution et 15 500 pour les frais d’avocat (avec une participation maximale de 8 000€), avec une Gold Mastercard elles s’élèvent à 15 500€ pour la caution et 15 500 pour les frais d’avocat (avec participation maximale de 3 100€), avec une Visa Classic ou Électron elles sont de 7 700€ pour la caution et 3 100€ pour les frais d’avocat (avec participation maximale de 800€), et avec une Mastercard classique @ccess ou Maestro elles sont de 3 100€ pour la caution et 3 100€ pour les frais d’avocat (avec participation maximale de 800€).

Envoi de médicaments, lunettes, prothèses auditives à l’étranger
Cette garantie permet de couvrir les frais d’envoi à l’étranger de médicaments, lunettes ou prothèses auditives. Le titulaire peut également transmettre un message urgent à son employeur ou à l’un de ses proches. De plus, si le titulaire ne peut ramener son véhicule à son domicile, un chauffeur est mis à disposition pour le faire à sa place. Un rapatriement des animaux domestiques est également prévu avec ce genre d’assistance.

L’assistance perte, vol de papiers, cartes
En cas de perte ou de vol de ses papiers et/ou de sa carte, le bénéficiaire de la carte aura la possibilité d’obtenir une carte provisoire sous 48 heures ou une avance de fonds en espèce sous deux heures. La somme est fixée par l’établissement bancaire et peut varier entre 1000€ et 5000€. Par ailleurs, le titulaire pourra profiter de conseils sur les démarches administratives et consulaires.

Visite d’un membre de la famille en cas d’hospitalisation
S’il vous arrive un accident et que vous ou un membre de votre famille doit être hospitalisé pour plus de 10 jours sans moyen d’être transporté ailleurs, votre carte bleue peut faciliter la visite de vos proches. Cette assistance consistent à prendre en charge pour vous le titre de transport aller-retour et les frais d’hôtel sur 10 jours maximum, sur justificatifs, avec un plafond quotidien qui dépendra de la gamme de votre carte. Avec un carte Mastercard Classique, le plafond journalier sera de 65€ tandis qu’avec une Mastercard Gold, il sera de 125€ par jour. Au-delà de ces 10 jours, la prise en charge des frais de séjour à l’hôtel, un maximum pour toute la durée du séjour est fixé (375€ pour une carte Mastercard Gold).

Les bénéficiaires de l’assurance et assistance des cartes bancaires
Qui peut bénéficier des garanties liées à votre carte bancaire ?

Vous
Votre conjoint(e) ou concubin(e) (notoire ou partenaire Pacsé)
Vos enfants de moins de 25 ans fiscalement à charge
Vos ascendants ou descendants vivant sous le même toit et fiscalement à charge
Les garanties couvrent toutes ces personnes qu’elles voyagent ensemble ou séparément.

Les conseils BankBank :
Les garanties d’assistance sont aussi applicables pour les petits-enfants de moins de 25 ans célibataires qui voyagent avec leurs grands-parents, titulaires d’une carte Visa Premier.

Assurer son compte et sa carte, est-ce-utile ?
Les banques l’appellent l’assurance des moyens de paiement, elle est l’assurance qui vous protège des incidents liés à l’utilisation frauduleuse de votre compte, le piratage de votre carte, la perte ou le vol de votre chéquier et de votre carte bancaire. En général, elle est incluse automatiquement dans les formules bancaires et son prix varie entre 14€ et 40€ par an, avec une moyenne de 25€ par an. Mais lorsqu’on connaît la réglementation qui protège les clients des banques, on se demande légitimement si cela vaut vraiment le coup de payer tant pour un service dont on peut n’avoir jamais besoin.

Selon la loi, vous bénéficiez déjà d’un certain niveau de protection sur vos comptes et vos moyens de paiement. Nous nous sommes donc attelés à expliquer comment vous êtes couverts avec et sans cette assurance basique des moyens de paiement. On n’abordera pas ici les autres types d’assurances qui s’appliquent sur l’auto, les biens et les personnes : elles varient largement entre les banques et elles nécessiteraient un livre entier à elles-seules.

L’utilisation frauduleuse de votre carte bancaire
Ici, pas de vol ni de chéquier ni de carte bancaire, mais un piratage courant de votre carte à distance, soit depuis internet soit depuis un distributeur d’argent trafiqué par des voleurs. La loi est claire dans ce cadre :

Sans assurance : l’article L133-19 du code monétaire et financier pose la règle selon laquelle vous n’aurez jamais rien à payer et la banque devra vous rembourser intégralement des sommes détournées. « la responsabilité du titulaire d’une carte n’est pas engagée si l’opération de paiement non autorisée a été effectuée en détournant, à l’insu du payeur, l’instrument de paiement ou les données qui lui sont liées ». Bien sûr en cas de tentative de fraude ou de négligence grave de votre part, la banque cherchera à annuler cette protection.

Avec assurance : Dans ce cas, l’assurance s’avère utile si la fraude a été effectuée avec une négligence ou une faute lourde de votre part, notamment si vous communiquez votre code confidentiel à la personne qui utilisera votre carte bancaire ou bien si vous placez la carte dans un endroit accompagnée du code secret écrit sur un bout de papier. La banque doit toutefois fournir une preuve de cette faute. Le client pourra être dédommagé moyennant une franchise de 150€ et seuls les montants débités après la mise en opposition seront pris en charge par la banque. L’assurance des moyens de paiement prendra en charge ces différents frais.

Les conseils BankBank : Il vous faudra agir dans les 13 mois suivant la fraude pour que votre demande de remboursement soit reçue par la banque.

La perte ou le vol de votre carte bancaire
Si vous avez perdu votre carte bancaire ou votre chéquier, votre moyen de paiement sera peut être utilisé à votre insu jusqu’à que vous fassiez opposition. Pire scénario : on vous vole votre carte bleue : il faudra porter plainte et déclarer le vol dans un commissariat ou à l’étranger, dans un poste de police.

Sans assurance : En cas d’opérations réalisées suite à une perte ou un vol de carte, les montants débités avant la mise en opposition au-delà de 150€ et après la mise en opposition sont pris en charge par la banque. Encore une fois, un comportement négligent de la part du détenteur de la carte peut conduire à un refus d’indemnisation : carte non signée au dos, carte accompagnée de son code secret, code secret donné au voleur…

Avec assurance : Les paiements frauduleux effectués avec le code secret seront pris en charge intégralement avec l’assurance des moyens de paiement.

Les conseils BankBank : Une réédition avant échéance de votre carte bancaire vous coûtera entre 10€ et 15€ (gratuit chez certaines banques si vous avez une carte haut de gamme). Si vous ne trouvez pas votre carte et que vous avez un doute, n’hésitez pas une seconde et faites opposition immédiatement. Il faut mieux en effet attendre une semaine pour avoir une nouvelle carte et plutôt que de voir son compte vidé pendant quelques semaines (le temps que la banque vous rembourse), moins les 150€ de franchise si vous n’avez pas d’assurance.

Le vol de chéquier, papiers, clés
Sans assurance : Les opérations réalisées en cas de perte ou de vol de chéquier nécessitent la preuve d’une falsification de la signature pour être prises en charge par la banque. Pas d’indemnisation en cas de perte ou de vol des papiers d’identité, des clefs, téléphone portable ou autre appareil mobile. Le vol d’espèces par agression à un distributeur ou l’extorsion du code bancaire ne sont pas non plus pris en charge sans assurance.

Avec assurance : En cas de perte ou de vol de chéquier, les opérations effectuées sont automatiquement prises en charge. La perte ou le vol des papiers d’identité, des clefs, du téléphone portable ou autre appareil mobile sont pris en charge avec l’assurance des moyens de paiement mais avec des plafonds limités et uniquement si votre chéquier ou votre carte ont été volés en même temps.

Le vol d’espèces suite à une agression à un distributeur
Sans assurance : En cas de vol d’espèces par agression à un distributeur ou d’extorsion de votre code bancaire, vous ne serez pas pris en charge sans assurance.

Avec assurance : Avec une assurance moyens de paiements, vous serez automatiquement indemnisé, mais le plafond de remboursement sera souvent bien plus bas que celui pour la perte ou le vol de la carte sans agression. Comptez souvent au jusqu’à 1000€ maximum pour un vol par agression, même réduit à 500€ si le retrait est effectué dans un distributeur d’une autre banque. Ces assurances s’appliquent même si on vous a extorqué votre code durant l’agression.

Alors, à quoi sert cette assurance que proposent les banques ?
L’intérêt d’assurer vos comptes et vos moyens de paiement réside plutôt dans les protections qui portent sur la perte ou le vol de votre téléphone portable, de vos papiers d’identité, de vos clés de maison ou de voiture. Mais là encore, les plafonds de remboursement pratiqués sont souvent très bas et moins compétitifs que des assurances classiques proposées par des assureurs professionnels. Et il faudra toujours que vos papiers, clés ou mobiles volés le soient en même temps que vos moyens de paiement assurés.

Les conseils BankBank : Relisez bien les conditions de couverture du contrat d’assurance proposé et allez comparer leurs conditions avec les assureurs professionnels qui vous proposeront sûrement des taux plus bas et des protections plus larges adaptées à vos besoins.

Le coût des opérations réalisées avec une carte
Effectuer des retraits avec sa carte
Les retraits en zone euro
Alors que les retraits dans un distributeur automatique de billets (DAB) ou à un guichet automatique (GAB) d’une même banque est gratuit, cela n’est plus le cas dès lors que les retraits se font sur les DAB ou GAB d’un autre établissement. Le montant s’élève généralement à 1€ dès le premier retrait ou après plusieurs retraits gratuits mensuels et qui dépendent de la banque et de la carte choisies. Les cartes plus prestigieuses que les cartes classiques internationales (Premier, Gold, Platinum, Infinite, World Elite…) permettent le plus souvent d’effectuer des retraits en illimité gratuitement à tous les distributeurs de la zone euro.

Les retraits hors zone euro
Les cartes internationales de paiement et de retrait permettent de retirer de l’argent partout dans le monde. Ce type d’opération engendre des frais généralement calculés sur une part fixe et une commission en pourcentage qui peut être encadrée par un seuil minimal et maximal. Quant aux banques en ligne, elles facturent les retraits internationaux sur la base d’une commission en pourcentage uniquement.
Selon les banques, la commission varie de 1,95% à 3,10% et la part fixe de 1,50€ à 5€.

Effectuer des paiements avec sa carte
Les paiements zone euro
En zone euro, les paiements par carte ne sont généralement soumis à aucun frais bancaire et ce, quelque soit la carte ou l’établissement bancaire.

Les paiements hors zone euro
Généralement plus avantageux que le retrait d’espèces, le paiement par carte à l’étranger engendre lui aussi certains frais calculés plus généralement sur une base de commission en pourcentage (parfois avec un seuil maximal et minimal) variable entre 1,50 % et 3,10 % pouvant être combinée à un montant fixe variable entre 0,20€ et 1,20€.

Les incidents liés à votre carte bancaire
L’utilisation de votre carte bleue peut amener à toutes sortes d’événements appelés des “incidents” par la banque. Que ce soit la perte de votre carte bleue ou bien de votre code, les incidents amènent en général à payer des frais parfois peu ou pas transparents dans la manière dont ils sont présentés par les banques. Chez certaines banques, tous ces frais sont complètement gratuits : à regarder donc, particulièrement si cela vous arrive plus d’une fois par an.

Les frais d’opposition sur carte bancaire
En cas de perte, vol ou fraude de la carte bancaire, la mise en opposition est une étape obligatoire pour éviter tout incident supplémentaire et se voir rembourser les sommes débitées. L’opposition doit se faire dans les plus brefs délais afin de limiter les conséquences. Faire opposition est irréversible chez les établissements bancaires traditionnels (et même les banques en ligne) et donne lieu à l’impossibilité d’utiliser la carte par la suite. Si la carte est retrouvée suite à une distraction, il faudra malgré tout commander une nouvelle carte bancaire. Cela n’est pas le cas avec les cartes fournies chez les néobanques avec leur compte de dépôt pour simplement dépenser dans la limite du solde : les comptes C-Zam de Carrefour ou bien ceux du compte Nickel offrent cette option très pratique blocage/déblocage à volonté la carte : en cas de doute, je peux bloquer ma carte directement sur l’application. Et si je la retrouve quelques heures après, je peux la débloquer aussi facilement, sans coût et sans stress.

Lors d’un incident, la banque doit prendre en charge l’intégralité des sommes débitées. Cependant, si la responsabilité du titulaire de la carte est engagée, par exemple en communiquant son code confidentiel, ou si le titulaire omet de faire opposition, des frais s’élevant à 150€ resteront à sa charge.

Faire opposition, la démarche à suivre
Étape 1 : Informer sa banque par téléphone via son numéro direct ou par le biais du serveur interbancaire au 0825705705. Ce numéro est également affiché sur les distributeurs automatiques de billets et/ou au dos des tickets de retraits. Pour cette seconde option, il sera utile de connaître les 16 chiffres de la carte ainsi que sa date d’expiration pour une recherche rapide de la part du serveur. S’ils sont notés, il est indispensable de les conserver dans un endroit sécurisé. Un numéro d’enregistrement sera fourni. Ce code sera à conserver. Certaines banques proposent un service d’opposition sur leur site. Enfin, il est possible de se rendre au guichet de son agence bancaire pour signaler l’incident.

Étape 2 : En cas de vol, un dépôt de plainte devra être fait à la police ou à la gendarmerie qui fournira un procès-verbal à conserver. A l’étranger, il faudra se rapprocher du consulat ou de l’ambassade.

Étape 3 : Confirmer l’opposition à sa banque par lettre recommandée avec accusé de réception. La lettre explicative sera accompagnée du numéro d’enregistrement obtenu lors du signalement au serveur interbancaire ainsi que de la copie du dépôt de plainte en cas de vol.

L’opposition par la banque pour usage abusif de la carte
Une carte bancaire étant la propriété de la banque, cette dernière peut la bloquer si son titulaire en fait une utilisation abusive. Par utilisation abusive s’entend une opération réalisée avec la carte bancaire alors que le solde du compte n’est pas suffisant ou le découvert autorisé dépassé. L’établissement ne procède pas automatiquement à la mise en opposition dès le premier incident mais en informe le client. Si l’abus persiste, la carte sera bloquée et donc inutilisable. Le client devra alors la rapporter à son agence bancaire. A défaut, et s’il continue de l’utiliser, la carte sera avalée par un distributeur automatique de billets. La banque peut également décider d’inscrire son client au fichier central des chèques recensant les infractions des paiements avec chèques ou carte de paiement. Ce fichier est consulté par les banquiers avant une ouverture de compte ou délivrance des moyens de paiement. Il est difficile d’établir une fourchette de prix fixés par les banques puisque toutes ne communiquent pas ces informations. Pour les frais connus, ils sont variables de 0€ à 56€ par opposition.

Les conseils BankBank
Des frais supplémentaires peuvent venir s’ajouter à l’opposition par la banque tels que des frais de déclaration à la Banque de France (allant jusqu’à 82€ pour les frais connus) et de capture de la carte (de 0€ à 263,60€ pour les frais connus).

La réédition du code confidentiel de la carte bancaire
Oubli du code confidentiel sans blocage de carte
Un oubli de code confidentiel, peut arriver. Vous disposez de 3 essais d’affilée pour composer votre code confidentiel. Si vous composez trois codes sans trouver le bon, votre carte sera bloquée. Selon les situations et les banques, des frais de réédition de code confidentiel voir de fabrication de carte peuvent s’ensuivre.

Blocage de carte après 3 essais lors d’un paiement
Lors d’une tentative de paiement par carte et après 3 essais de code, la carte est automatiquement bloquée. Si le client se souvient de son code, il lui suffira de se rendre en agence ou de téléphoner pour débloquer sa carte. En revanche si l’oubli est définitif, il devra faire rééditer un code confidentiel, voire commander une nouvelle carte.

Capture de carte après 3 essais à un distributeur automatique
Après avoir entré trois fois de suite un code erroné, la carte bancaire est systématiquement avalée par le distributeur automatique. Le client peut se rendre à l’agence concernée dans les plus brefs délais avec une pièce d’identité pour tenter de récupérer sa carte. S’il se souvient de son code, il pourra procéder au déblocage de sa carte en agence ou par le service de banque à distance. Dans le cas inverse, il devra faire une demande de réédition de code. Si le blocage est définitif, il devra malgré tout commander une nouvelle carte.
Si le particulier ne parvient pas à récupérer sa carte, il devra simplement en commander une nouvelle.

Les frais de refabrication de carte
Pourquoi avoir recours à la fabrication d’une nouvelle carte ?
Plusieurs motifs peuvent amener à commander une nouvelle carte :

Suite à une mise en opposition pour perte, vol ou utilisation frauduleuse de la carte
A cause d’une carte défectueuse
A cause d’un blocage définitif de la carte suite à la composition trois fois d’affilée du code confidentiel
Après qu’un distributeur automatique ait avalé la carte sans possibilité de la récupérer après la composition trois fois d’affilée du code confidentiel ou son blocage définitif
Comment demander la fabrication d’une nouvelle carte ?
La demande peut se faire directement en agence, par téléphone ou encore depuis l’espace client du site de la banque.

Quels sont les délais et le coût de la fabrication d’une nouvelle carte ?
Après un délai moyen de 5 jours, il est possible de retirer la carte en agence ou de la recevoir par courrier (cet envoi pouvant induire des frais supplémentaires selon le type de lettre).

La tarification d’un remplacement de carte varie en fonction des banques et du motif mais va de la gratuité au prix de la cotisation carte.

Combien vous coûte votre compte bancaire ?
Les frais de votre compte bancaire
Pour les français, le compte courant est une évidence. Pour se rémunérer et délivrer leurs services en agence, les banques traditionnelles répartissent leurs charges sur des options de service variées comme l’ouverture et la tenue du compte, les moyens de paiement, les découverts, sur les assurances et les opérations à l’étranger. Les banques en ligne, elles, jouent le tout gratuit puisqu’elles n’ont pas à faire payer la lourde charge que représente l’agence physique. Elles se rentabilisent sur des options offertes en parallèle du compte courant, comme l’épargne, les crédits et les placements.

De très nombreuses combinaisons d’offres et de services sont donc offerts, à travers des formules aux noms marketing parfois déconcertants et souvent au détriment d’une transparence absolue sur ce pour quoi on paye chaque mois. Et en effet, un coup d’œil aux tarifs légalement affichés par chacun des banques peut parfois décourager les clients de s’y plonger dans le détail. Les banques en ligne 100% digitales avaient bien compris cette détresse des consommateurs et en ont fait depuis quelques années leur argument principal. Simplifier devient la norme comme de rendre le contrôle aux consommateurs. Nous avons disséqué les tarifs bancaires pour vous en dessiner un aperçu non exhaustif afin de vous faire comprendre les enjeux de choisir la transparence et la simplicité.

La convention de compte, le contrat de votre compte bancaire
La convention de compte est le contrat entre vous et votre banque, qui décrit tous les services et obligations qui y sont liées.

Toutes les données du contrat entre vous et votre banque
Selon la définition du site du Service-Public.fr, la convention de compte “contient la totalité des engagements contractuels réciproques entre vous et votre banque, de l’ouverture à la clôture de votre compte.” Une convention de compte bancaire doit donner des informations sur :

La durée de la convention, les services proposés et leurs tarifs
Les moyens de communication utilisables entre la banque et vous
Les conditions de procuration
Le sort du compte en cas de décès
Les possibilités de modification et de résiliation de la convention
Les voies de médiation et de recours en cas de contestation
Des informations précises sur les moyens de paiement
Une convention doit également donner des informations précises sur les moyens de paiement associés au compte, notamment sur :

La mise à disposition ou non de chèques
Les obligations en matière de sécurité et de protection relatives à l’utilisation des moyens de paiement
Les procédures en cas d’opérations mal exécutées
Les procédures en cas d’incidents de paiement
Les possibilités de limitation du montant des dépenses offertes pour chaque moyen de paiement
Les frais, les taux d’intérêt et de change applicables.
Les conséquences des incidents bancaires comme les découverts
La convention mentionne les conséquences d’une position débitrice du compte, en précisant :

Les modalités d’un éventuel découvert autorisé (conditions d’utilisation, conditions tarifaires)
Les conséquences d’une position débitrice non autorisée, les conditions dans Lesquelles vous êtes informé ainsi que le tarif applicable
Les possibilités de blocage ou de restitution de moyens de paiement
Les possibilités de ponction éventuelle par la banque de sommes inscrites sur d’autres comptes bancaires gérés par elle ( comptes d’épargne par exemple), pour couvrir le déficit.
Évidemment, rares sont les clients qui lisent de bout en bout leur convention de compte. Et pourtant, cela est essentiel pour bien saisir ce pour quoi on paye. Pour cela, nous en proposons une lecture simplifiée en tenant compte à chaque fois de l’élément de comparaison le plus évident pour votre compte bancaire : le prix de chaque service.

Les frais liés à la tenue de votre compte
Les frais de tenue de compte
Ces frais sont apparus assez récemment dans les conditions tarifaires des banques. Ils sont exigés par la plupart des banques de réseau et sont justifiés par la difficulté croissante de maintenir un réseau géographique étendu et performant à l’heure de la digitalisation et de la gratuité des services. Ils rémunèrent donc les moyens humains et informatiques liés à la gestion du compte. Leur montant s’élève annuellement de 12€ à 36€ par an avec une moyenne de 24€ à l’année. Ces frais de tenue de compte sont exonérés lorsque vous choisissez une formule de compte, et c’est là ou il faut être attentif car les banques compensent cette gratuité sur d’autres services ou options telles que les assurances, les autorisations de découvert ou bien l’envoi des alertes et notifications.

Le relevé bancaire : outil indispensable de la tenue de compte
La tenue de compte implique l’envoi gratuit de relevés mensuels chez vous ou bien sur votre boîte mail. Ces documents recensent toutes les opérations effectuées sur le compte durant le mois écoulé. Vous avez toujours la possibilité de recevoir ces relevés de façon plus fréquente mais ce service sera alors facturé.

Les conseils BankBank
Les relevés bancaires doivent être conservés durant 5 ans correspondant au délai de droit civil. Même si aucune anomalie n’est constatée, ce délai permet de garder une preuve si un litige lié à une opération de débit ou de crédit venait à naître durant cette période. Si les relevés sont dématérialisés, la banque se charge de les conserver durant ce même délai.

L’édition de BIC/IBAN : une empreinte bancaire
L’International Bank Account Number, appelé par son diminutif IBAN, correspond à une combinaison unique de chiffres et de lettres permettant la reconnaissance particulière de chaque compte. Quant au Bank Identifier Code ou BIC, il représente l’identifiant international d’une banque. L’édition de ces identifiants, figurant tous deux sur vos relevés d’identités bancaires, est légalement gratuite.

La clôture de compte bancaire
Autrefois facturés par les établissements bancaires, les frais de clôture de compte sont aujourd’hui gratuits grâce aux mesures mise en place par les pouvoirs publics afin de faciliter la mobilité bancaire.

Les conseils BankBank
Cette gratuité ne concerne pas certains produits d’épargne et de placement qui ne sont pas non plus transférables. Voir le Chapitre Changer de Banque.

La facture des comptes inactifs
Chez toutes les banques, des frais sont prélevés pour les comptes inactifs pendant un certain temps et sont légalement plafonnés à 30€ par mois. Il est donc dans votre intérêt de bien clôturer vos comptes avant d’en changer afin de ne pas voir le solde que vous y avez laissé fondre jusqu’à 420€ par an.

Les conseils BankBank
Un compte est considéré comme étant inactif lorsqu’aucune opération n’a été effectuée sur le compte par le titulaire ou son représentant légal durant 12 mois consécutifs et qu’aucun d’eux ne s’est manifesté auprès de la banque.

Les frais liés aux services en agence
Les service en agence : le coût de la proximité
Le service en agence représente un coût important qui varie fortement en fonction des caractéristiques de chaque banque de réseau. C’est une des raisons qui explique les différences de prix connues entre les banques traditionnelles qui disposent d’agences réparties sur le territoire et les banques en ligne complètement dématérialisées.

Les services payants en agence bancaire
Parmi ces services :

La recherche de documents dont les frais peuvent varier selon le type de document ou son antériorité
La délivrance d’attestations
Les frais liés à un coffre-fort (location, ouverture forcée…)
Le retrait d’espèce au guichet agence d’une autre banque
Les virements vers une autre banque
L’ajout d’un émetteur sur sa liste noire (opposition permanente), retrait de consentement pour les prélèvements
L’ajout d’un émetteur à sa liste de limitation du montant ou de la périodicité de prélèvements
L’ajout d’un émetteur à sa liste Blanche (autorisation) de prélèvement
Les conseils BankBank :
Il est plus avantageux, lorsque cela est possible, de réaliser ces services sur internet car ils ne vous seront pas facturés dans la majorité des cas (sauf opération exceptionnelle). Cela peut être le cas pour les virements ou les ajouts d’émetteurs au listes de prélèvements.

Les frais liés aux services de gestion à distance de votre banque
L’abonnement à des services de banque à distance
L’abonnement à des services de banque à distance vous permet de réaliser des opérations quotidiennes via internet ou par téléphone. Vous pouvez consulter votre compte à distance, effectuer des virements internes ou externes, communiquer avec votre conseiller ou encore éditer des relevés d’identité bancaire. Ce service, indispensable aujourd’hui, est gratuit pour la majorité des banques (hors coût fournisseur d’accès). Lorsqu’il est payant (généralement dans les banques régionales ou quelques branches locales des banques nationales), l’abonnement aux services de banque à distance s’élèvera au maximum à 4€ par mois.

Le service d’alerte SMS de la situation du compte
La souscription à un abonnement de service d’alerte permet de recevoir des SMS afin d’être informé sur les différents mouvements du compte bancaire, notamment :

Le solde du compte
Les opérations réalisées et à venir
Le dépassement du découvert autorisé
Le contrôle de solde pour chaque paiement par carte offrant cette option
Les conseils BankBank : il est courant que cette option soit activée par défaut dans votre offre, il est très simple d’en demander l’arrêt pour des raisons de budget (ce service peut coûter entre 12€ et 40€ par an) ou si vous estimez qu’un suivi par application mobile de vos compte est suffisant.

En général, l’abonnement à l’option de notification par SMS vous coûtera 24€ par an, auquel peuvent s’ajouter selon les banques des frais par SMS s’élevant autour des 0,20€ par SMS. La tarification de ce service dépend des établissements et se fait selon différentes formules :
Service gratuit : Essentiellement chez les banques en ligne comme BforBank, Boursorama, Hellobank, ING Direct ou encore Monabanq. Parmi les banques dites classiques, le Crédit Agricole Sud Rhône Alpes et Centre-Ouest offrent également ce service.

Service gratuit avec un nombre limité de SMS (de 4 à 10 par mois) : Une fois ce seuil dépassé, les SMS sont facturés par tranche ou à l’unité avec des prix variant de 0,25€ à 0,30€ par alerte. Fortuneo, AXA Banque ou encore le Crédit Maritime Sud-Ouest proposent ce type de formule.
Coût à l’unité du SMS allant de 0,20€ à 0,75€ selon les établissements : Ce fonctionnement est notamment mis en place par la Banque Chalus, la Banque de Savoie ou le Crédit Mutuel du Massif Central. Il faudra compter recevoir en moyenne 4 SMS par mois.
Forfait mensuel payant comprenant un nombre limité de SMS puis un coût à l’unité pour les SMS supplémentaires : Ce forfait peut coûter de 1€ à 4€ par mois. Le CIC, LCL, et la Banque Populaire Alpes proposent cette formule.
Forfait mensuel payant avec SMS en illimité : Le prix du forfait varie entre 0,75€ et 4,20€ par mois selon les banques. Ce type d’abonnement est disponible, entre autre, à la Caisse d’Épargne, la Société Générale ou la Banque Palatine.

Les conseils BankBank
Souscrire à ce type de service s’avère utile lorsque le client ne dispose pas de la possibilité de consulter ses comptes sur l’application (téléphone mobile inadapté, pas de forfait internet…). Sinon, un accès direct à votre application mobile vous permet un suivi en direct et gratuit de votre activité en temps réel et rend ces options inutiles.

Les frais liés aux moyens de paiements hors carte
Les opérations de virements en zone SEPA
Un virement SEPA est une opération de transfert d’argent d’un compte bancaire vers un autre, tous deux basés dans l’espace qui comprend les pays de la zone euro dans l’Union européenne mais aussi des pays hors zone euro comme l’Islande, le Liechtenstein, la Norvège, Monaco, la Suisse et Saint-Marin. Le délai de transfert est immédiat pour un virement vers une même banque tandis qu’il est en moyenne de 1 à 2 jours ouvrables vers un autre établissement.
La zone SEPA en carte :

Les tarifs des virement SEPA occasionnels
En général, ces virements coûtent entre 2€ et 6€ par virement entrepris en agence, tandis qu’ils sont gratuits s’ils sont lancés depuis votre compte sur Internet. Ils sont toujours gratuits chez les banques en ligne.

Les frais par virement occasionnels émis à la Banque Postale :

L’émission d’un virement SEPA est toujours gratuite si elle est lancée depuis internet (dans la limite des plafonds autorisés).

Si elle est faite en agence :

Entre comptes tenus à La Banque Postale : Gratuit
Vers un compte tenu dans une autre banque : 3,30€ par virement

Les tarifs des virement SEPA permanents
Les virements permanents sont programmés pour s’exécuter automatiquement selon vos besoins. Chez les banques de réseau, le tarif pour la mise en place de ces virements varie largement. Ils sont toujours gratuits pour les banques en ligne. Chez la plupart des banques traditionnelles, le virement permanent est gratuit s’il est effectué en ligne, ou s’il est effectué en agence vers un compte de la même banque.

Si le virement est installé depuis une agence et qu’il est destiné à un compte d’une autre banque, il sera facturé très différemment :
Soit à l’installation du virement, par exemple 7,50€ pour l’installation du virement permanent au CIC
Soit pour chaque virement effectué, par exemple 1,20€ par virement pour la BNP, ou 1,40€ par virement chez LCL

Les opérations de virements hors-zone SEPA
Plus onéreux qu’un virement en zone SEPA, un virement hors-zone SEPA donne forcément lieu à des frais perçus par la banque. En plus des frais d’émission du virement, des frais de réception peuvent être saisis. Ici encore, envisager la solution internet peut s’avérer moins coûteuse que le passage en agence ou par téléphone. Comptez chez les banques en ligne à une taxe de 0,10% du montant, comme la plupart des banques de réseau. Un taux minimal s’applique généralement, entre 9€ et 40€ par virement, avec un plafond maximum, parfois, de 70€ par virement.

Les opérations de prélèvements
En quoi consiste une opération de prélèvement ?
Le prélèvement est une opération de transfert d’argent régulier d’un compte vers un autre initiée par le débiteur, c’est à dire celui qui recevra la somme prélevée de votre compte. Ce moyen de paiement est couramment utilisé pour payer ses factures auprès d’organismes publics ou d’abonnements mensuels. Il faut pour cela signer un mandat de prélèvement auprès de son créancier.

La majorité des établissements bancaires ne facture pas la mise en place ni le transfert de chaque prélèvement. Toutefois, elles se rattrapent au moment de terminer cette autorisation de prélèvement : vous avez en effet la possibilité révoquer l’option avant de vous faire prélever, ou bien de faire opposition sur un prélèvement qui a déjà eu lieu :
Chez les banque de réseau, les autorisations de prélèvement sont le plus souvent proposées gratuitement, et vous serez ensuite facturés sur les options de révocation (en moyenne 16,50€ par révocation) et d’opposition (en moyenne 30,80€ par opposition).
Chez les banque en ligne la mise en place d’une autorisation de prélèvement et les frais pour chaque exécution sont toujours gratuits, tout comme la révocation de l’autorisation et les frais d’opposition.

L’alternative du paiement par TIP SEPA
Il existe une variante au prélèvement SEPA nommée TIP SEPA. Ce service, généralement gratuit, permet également d’effectuer un transfert d’argent initié par le débiteur. Le paiement par TIP SEPA se distingue du prélèvement par le fait qu’il accompagne d’une facture et qu’il faut le renvoyer signé à chaque fois à son créancier pour que le paiement soit valable.

Lorsque ce type de paiement est effectué pour la première fois ou si vos coordonnées bancaires viennent à changer, il faudra remplir un formulaire qui aura valeur de prélèvement SEPA et renvoyer le TIP SEPA accompagné d’un relevé d’identité bancaire ainsi que du formulaire. Pour les paiements suivants, le TIP SEPA sera complété avec les coordonnées bancaires, il suffira de le signer et de le retourner au fournisseur de service.

Les conseils BankBank :
Un ajout de créancier à sa “liste blanche” (donc autorisé à effectuer des prélèvements) ou à sa “liste noire” (interdit de prélèvement) peut entraîner des frais si elles sont demandées au guichet d’une agence. Souscrire à ces options sur internet peut permettre de réaliser ces opérations gratuitement.

Le paiement par chèque
Aujourd’hui en perte de vitesse, le chéquier est le 4eme moyen de paiement utilisé par les français après la carte bancaire, les espèces et les prélèvements. Il est abandonné progressivement puisqu’il ne présente que des désavantages par rapport aux paiements par carte et aux transferts immédiats.

Le coût d’un chéquier
Sauf en cas d’interdit bancaire, la mise à disposition d’un chéquier est accordée à tous les clients d’une banque et ce, gratuitement. Cette gratuité apparente peut toutefois être combinée à des frais supplémentaires, variables selon chaque banque :

L’envoi en recommandé du chéquier
L’émission d’un chèque de banque destiné à régler des sommes importantes et prouvant la provision suffisante du compte
La destruction du chéquier en cas de non retrait après une durée déterminée
Des frais d’opposition
Des frais de rejet
La lettre d’information relative au frais de rejet
Payer par chèque à l’étranger
Il est fortement déconseillé de payer par chèque à l’étranger. Cela reste possible dans les pays de la zone euro si le commerçant accepte ce moyen de paiement. Cependant, les frais engendrés seront onéreux, variant en moyenne autour des 10% du montant du chèque s’il est inférieur à 150€, puis avec un minimum de 25 à 27€ par chèque et un pourcentage sur la valeur de 0,1%.

Les frais liés aux incidents sur votre compte bancaire
Par incident, on entend ici tout événement imprévu lié à un dépassement de votre plafond.

Les conséquences du découvert sur votre compte
Un découvert se paye cher dans la plupart des banques. Voici comment sont calculés les frais qui vous sont facturés lorsque vous dépassez votre budget :

Les agios
Les agios représentent tous les frais perçus par la banque pour le bon fonctionnement du compte en cas de “dysfonctionnement” ou de “gestion anormale” et sont perçus pour couvrir la gestion des opérations qui en découlent. La banque autorise son client à lui “emprunter de l’argent” temporairement et donc lui autorise un découvert sous conditions. Par l’application d’agios, le manque de liquidité des clients est absorbé de façon à couvrir les sommes engagées par la banque. Ils sont composés des intérêts débiteurs cumulés avec des commissions d’intervention.

Les intérêts débiteurs
Que le découvert soit autorisé ou non, il est toujours assorti d’intérêts débiteurs. Ces intérêts désignent les sommes prélevées par l’établissement bancaire dès lors que le compte client est en négatif et dépendent du montant de la durée du découvert. Ils sont librement fixés par les banques mais obligatoirement inférieurs au taux de l’usure. Facturé généralement à chaque trimestre, le montant des agios est établi à partir d’un taux annuel effectif global (TAEG). Ce taux diffère suivant les banques, mais aussi selon que le découvert soit autorisé ou non par convention (exemples : des offres groupées avec une autorisation de découvert chiffrée qui représente la limite du découvert). Le TAEG varie entre 10 et 20% du découvert perçus.

Par exemple, si un client a été débiteur de 500€ pendant 15 jours et que sa banque applique un taux de 17 %, le montant des agios sera calculé de la manière suivante :

500€ de découvert x 15 jours x TAEG de 17 % / 365 jours = environ 3,49€

Les conseils BankBank: Fixé par la Banque de France, le taux de l’usure correspond au seuil maximal auquel un établissement financier peut accorder un prêt et, en l’occurrence, le seuil à ne pas franchir pour l’application de taux en cas de découvert.

Les commissions d’intervention
Si votre découvert n’est pas autorisé par convention avec votre banque, il faut absolument prévoir les frais de commission d’intervention qui seront facturées en moyenne 8€ par opération débitrice : chaque retrait et paiement effectué lorsque votre compte est dans le rouge. Ils sont limités à 80€ par mois et pour certain cas exceptionnels, notamment les personnes en situation de fragilité financière, le plafond est de 4€ par opération et 20€ par mois. La commission d’intervention est le vrai cauchemar du budget limité : il entraîne en effet une spirale d’endettement qui aggrave la situation de jour en jour, tant que le compte est à découvert. Dans le cas concret du titre précédent, on a imagine donc que le découvert de 15 n’a pas été couvert:

15 jours x 8€ = 120€ limités à 80€ + 3,49€ = 83,49€ pour le mois.

Les banques sont dans l’obligation d’indiquer sur chaque relevé de compte mensuel le plafond de l’autorisation de découvert, ainsi que le taux annuel effectif global (TAEG) appliqué.

Les conseils BankBank :
Les banques en ligne BforBank, ING Direct, Boursorama Banque, Hello Bank et Fortuneo ne facturent pas de commission d’intervention et les intérêts débiteurs y sont généralement moins élevés que ceux pratiqués par les banques traditionnelles.

Contester des commissions d’intervention
Contester un TEG trop élevé

Il est possible de contester des commissions d’intervention si celles-ci dépassent le plafonnement de 80€ par mois prévu par la loi. Il est aussi possible en cas de TEG supérieur au seuil de l’usure. En effet, le fait d’inclure ces frais en supplément des intérêts dans le calcul du TEG peut l’amener à dépasser le taux de l’usure. Dans ce cas, vous pourrez vous appuyer sur l’arrêt n°06-20783 de la chambre commerciale de la Cour de Cassation du 5 février 2008 pour faire réduire ce TEG du mois au taux d’usage en cours. Il faudra faire part de votre requête à votre banque via un courrier et si besoin communiquer avec une association de consommateur pour bénéficier d’un soutien. En cas de refus, le client peut faire appel au médiateur bancaire pour résoudre le litige. Si le refus est persistant et que les sommes sont importantes, le dernier recours consiste à saisir la justice.

Les conseils BankBank :
Même lorsque la banque n’a pas enfreint les seuils réglementaires, vous pouvez toujours demander à votre conseiller de lui rembourser des commissions d’intervention prélevées. Le conseiller pourra alors faire un geste commercial si cela arrive pour la première fois.

L’obligation d’information 14 jours avant de ponctionner les commission d’intervention

Depuis le 1er janvier 2016, votre banque doit vous informer au moins 14 jours avant de débiter les commissions d’intervention de votre compte. Cela signifie que les commissions d’interventions ne seront pas retirées directement de votre compte au moment du découvert, “au fil de l’eau”. Cela créé un effet pervers au niveau de l’information sur votre situation : si la banque ne communique pas en direct avec vous lorsqu’elle décide qu’elle va vous facturer cette commission, vous perdrez l’effet d’annonce et d’urgence qu’entrainaît autrefois cette action. L’information décalée pourrait alors vous empêcher de prendre les bonnes mesures au bon moment pour contrer un éventuel empilement des charges.

Se protéger des frais liés aux découverts
Se protéger des coûts des découverts occasionnels grâce aux facilités de caisse
Un facilité de caisse, autrement dit une autorisation de découvert occasionnel, consiste à la mise en place d’une autorisation de solde négatif sur votre compte. Un montant et un taux d’intérêt personnalisés sont alors fixés par contrat avec votre banque. Les intérêts débiteurs sont proportionnels au montant et à la durée de la facilité de caisse. En cas de dépassement, le taux devient logiquement plus élevé que celui accordé pour la facilité de caisse et les commission d’interventions seront activées. Des frais de dossier ou de gestion peuvent intervenir dans la mise en place d’une facilité de caisse. Ils varient d’une banque à l’autre et apparaissent dans les conditions de tarifs de chaque banque, sauf de celles qui ne proposent pas de facilité de caisse.

Les banques en ligne attribuent automatiquement un découvert autorisé, variable selon les marques et appliquent parfois un montant par défaut : 200€ par BforBank, Fortuneo et 250€ pour Monabanq et ING Direct, 500€ chez Boursorama avec une carte Visa classique. Ces montants varient en fonction de votre situation et sont négociables avec un conseiller à distance. Lors de votre inscription chez la plupart des banques en ligne, vous avez le choix d’appliquer ou non une autorisation de découvert sur votre compte.

Les conseils BankBank : Le découvert autorisé non permanent est accordé pour une durée indéterminée à condition qu’il soit utilisé moins de 30 jours consécutifs.

Se protéger des coûts des découverts plus longs grâce à une autorisation de découvert permanent
L’autorisation de découvert permanent est également une autorisation de solde négatif sur le compte convenue entre votre banque et vous. La durée du découvert autorisée est plus longue que celle d’un autorisation de découvert occasionnel (ou facilité de caisse) mais ne peut excéder 90 jours. Dans le cas où cette situation se produirait, la banque doit soumettre au client une offre préalable de crédit dont les conditions et modalités respectent celles du crédit à la consommation. Le découvert permanent donne souvent lieu à des frais de dossier ou de gestion. Le solde négatif, quant à lui, fait l’objet d’intérêts débiteurs obligatoirement inférieurs au taux de l’usure (les taux fixés pour les découverts sans autre convention).

Les frais en cas d’incidents exceptionnels gérés par votre banque
Les “incidents” exceptionnels tels qu’on les entend ici, sont des découverts qui ne seront pas acceptés par la banque, car considérés comme anormaux. Ils s’appliquent donc très rarement et uniquement en cas d’abus évident de la part des clients ou bien lorsqu’une intervention directe de la banque est requise pour régler le problème. Par exemple, lorsque ce sont des prélèvements et non des paiements qui sont concernés, ou bien encore si cela concerne des chèques non payés ou sans provisions.

Les frais de rejet
Les frais de rejet interviennent lorsque des opérations sont effectuées alors que le compte ne dispose plus de la provision suffisante ou que le découvert autorisé est dépassé. Ils concernent notamment les opérations de prélèvements, virements et de paiements par chèque. Les frais de rejet amènent des charges :

La lettre d’information préalable
La lettre d’injonction
La commission d’intervention
La loi plafonne ces montants à 20€ pour les prélèvements, à 30€ pour les chèques inférieurs à 50€ et 50€ pour les chèques supérieurs à 50€.

La révocation et l’opposition de mandat de prélèvement
Pour faire cesser les prélèvements sur son compte il faut d’une part en informer le créancier via un courrier et d’autre, prévenir sa banque. En effet, un prélèvement s’effectue selon deux niveaux d’autorisation :

Pour le créancier à émettre des paiements sur le compte du créditeur
Pour la banque à effectuer des opérations de débit aux sommes présentées par le créancier
Ensuite, le créditeur dispose de deux choix pour empêcher le prélèvement : l’opposition ou la révocation dont la portée est différente.
Une opposition sur un mandat de prélèvement a un caractère ponctuel et ne remet pas en cause la signature du mandat de prélèvement. L’opposition est valable uniquement sur les opérations désignées, donc déjà initiées.
La révocation est plus lourde de conséquence. Elle a un caractère définitif et nécessite la signature d’un nouveau mandat pour toute nouvelle opération.

Dans les deux cas, les établissements bancaires peuvent facturer la demande et le montant de ces coûts est précisé dans leurs conditions tarifaires.

Les frais d’opposition sur chèque ou chéquier
L’opposition pour ce moyen de paiement peut porter sur un chèque ou sur le chéquier. Cette opposition se justifie uniquement en cas de :

Perte ou vol
D’utilisation frauduleuse
Redressement ou liquidation judiciaire du client
Les frais d’opposition sont payants pour la plupart des banques traditionnelles, mise à part la BNP Paribas qui propose ce service gratuitement s’il est fait sur internet. Les frais s’évaluent entre 2€ et 51,60€ pour un chèque et 10,50€ à 51,60€ pour une série de chèques ou un chéquier. Chez les banques en ligne, l’opposition est gratuite que ce soit pour un chèque, une série de chèques ou un chéquier. Exception faite chez Hellobank dont l’opposition est gratuite via internet mais qui facture 16€ pour l’opposition au chèque si elle est faite avec un conseiller Hellobank.

Les conseils BankBank :
Dès que l’incident est constaté, il faut agir le plus rapidement possible.
Contactez le centre national d’appel des chèques perdus ou volés au 0 892 683 208 (prix d’un appel local). Ce centre est disponible 24h/24 et 7j/7. L’opposition sera alors enregistrée pour 48 heures le temps de faire le signalement à sa banque. Pour vous protéger et faire la preuve de votre action à temps (et éviter de n’avoir rien à démontrer si la charge de la preuve tombe sur vous lorsqu’il faudra prouver votre action pour toucher les assurances et le dédommagement par la banque), confirmez l’incident par courrier recommandé à votre agence ou à votre banque en ligne.

Les frais de lettre d’information préalable pour chèque sans provision
Lorsqu’un chèque sans provision est remis à l’encaissement, la banque vous avertir via une lettre détaillant les délais de régularisation et les conséquences d’un rejet de chèque.

Chez la Banque Postale, les frais de lettre d’information préalable pour chèque sans provision sont facturés à 6€ par lettre.

Les frais de lettre d’information pour compte débiteur non autorisé
Depuis 2001, la Loi Murcef a instauré l’obligation pour les banques d’avertir leurs clients de tout incident de paiement imminent. Par conséquent, une lettre d’information est envoyée lorsque le découvert ou le dépassement de découvert intervient à la suite d’opérations non rejetées par l’établissement bancaire, ayant mis le compte en anomalie ou en cas d’opérations annulées. Cette lettre peut également être envoyée lorsque le découvert autorisée est d’une durée supérieure au délai contractuel. La facturation de ce service s’élèvera à un maximum d’une dizaine d’euros et est gratuit chez de nombreuses banques. La procédure aussi diffère selon les banques : facturation après information préalable dans le relevé bancaire mensuel, distinction entre envoi simple et envoi en recommandé selon la situation ou encore tarification progressive, chaque banque applique des règles différentes, expliquées en détail dans votre convention de compte.
		
				</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
